import axios from 'axios'
import sitesRoutes from './routes/sitesRoutes'

const axiosInstance = axios.create()
const sites = new sitesRoutes(axiosInstance)

function configure(apiServer) {
    axiosInstance.defaults.baseURL = (apiServer.ssl ? 'https' : 'http') + '://' + apiServer.host + ((apiServer.ssl && apiServer.port == '443') || (!apiServer.ssl && apiServer.port == '80') || !apiServer.port ? '' : ':' + apiServer.port) + '/1/'
}

function useAuth(auth) {
    axiosInstance.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
        return config
    })
}

const apiService = {
    configure,
    useAuth,
    sites,
}

export default apiService
