import Vue from 'vue'
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

export function createAuth(config) {
    config = {
        ...{
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            automaticSilentRenew: true,
            response_type: 'code',
            filterProtocolClaims: true,
            redirect_uri: `${location.protocol}//${location.host}/oidc/return`,
            silent_redirect_uri: `${location.protocol}//${location.host}/oidc/renew`,
            post_logout_redirect_uri: `${location.protocol}//${location.host}`,
            loadUserInfo: true,
        },
        ...config,
    }

    const mgr = new UserManager(config)
    mgr.events.addUserLoaded(newUser => (auth.user = newUser))
    mgr.events.addUserUnloaded(() => (auth.user = null))
    mgr.events.addAccessTokenExpired(() => (auth.user = null))
    mgr.events.addUserSignedOut(() => (auth.user = null))

    const auth = new Vue({
        data() {
            return {
                user: null,
                useRouter,
                signOut,
            }
        },
        computed: {
            isAuthenticated: function () {
                return !!this.user && !this.user.expired
            },
            accessToken: function () {
                return !!this.user && !this.user.expired ? this.user.access_token : ''
            },
            userProfile: function () {
                return !!this.user && !this.user.expired ? this.user.profile : { iss: '', sub: '', aud: '', exp: 0, iat: 0 }
            },
        },
    })

    function signOut() {
        return mgr.signoutRedirect()
    }

    function useRouter(router) {
        router.beforeEach(async (to, from, next) => {
            if (to.matched.some(record => record.meta.requiresAuth)) {
                if (auth.isAuthenticated) {
                    next()
                } else {
                    await mgr.signinRedirect({ state: { to } })
                    next(false)
                }
            } else next()
        })
        router.addRoute({
            path: new URL(config.redirect_uri).pathname,
            component: {
                render: h => h('div'),
                async created() {
                    var response = await mgr.signinRedirectCallback()
                    router.replace(response.state?.to || '/')
                },
            },
        })
        router.addRoute({
            path: new URL(config.silent_redirect_uri).pathname,
            component: {
                render: h => h('div'),
                created() {
                    mgr.signinSilentCallback()
                },
            },
        })
    }

    return auth
}
