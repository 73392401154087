import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import { createAuth } from '@/plugins/oidc'
import router from '@/router'

import slumlordApi from '@/services/slumlordApi'
import '@fontsource/quicksand'
import '@mdi/font/css/materialdesignicons.css'

(async () => {
    let response = await fetch(process.env.BASE_URL + 'config.json')
    const config = await response.json()
    const auth = await createAuth(config.oidc)

    auth.useRouter(router)
    Vue.prototype.$auth = auth

    slumlordApi.configure(config.slumlordApi)
    slumlordApi.useAuth(auth)
    Vue.prototype.$slumlordApi = slumlordApi

    new Vue({
        vuetify,
        router,
        render: h => h(App),
    }).$mount('#app')
})()

Vue.config.productionTip = false
