import Vue from 'vue'
import VueRouter from 'vue-router'

import Locations from '@/views/Locations'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            name: 'Locations',
            path: '/',
            component: Locations,
            props: route => ({ returnUrl: route.query.returnUrl }),
            meta: { requiresAuth: true },
            beforeEnter: (to, from, next) => {
                next((to.query.returnUrl ?? '').includes('unify.ws') || (to.query.returnUrl ?? '').includes('unify.fun') || (to.query.returnUrl ?? '').includes('localhost'))
            },
        },
    ],
})

export default router
